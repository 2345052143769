import React, { useEffect } from "react";
import "./style.scss";

const FullPageContainer = ({
  sectionItem,
  refs,
  activeSection,
  setActiveSection,
}) => {
  useEffect(() => {
    const observerConfig = {
      rootMargin: "-50% 0px -50% 0px",
      threshold: 0,
    };
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.target.id !== activeSection && entry.isIntersecting) {
          setActiveSection(JSON.parse(entry.target.id));
        }
      });
    };
    const observer = new IntersectionObserver(
      handleIntersection,
      observerConfig
    );
    observer.observe(refs[sectionItem.name].current);
    return () => observer.disconnect(); // Clenaup the observer if component unmount.
  }, []);

  return (
    <div
      className="full_width_section"
      ref={refs[sectionItem.name]}
      id={sectionItem.id}
    >
      {sectionItem.component}
    </div>
  );
};

export default FullPageContainer;
