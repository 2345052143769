import "./style.scss";

import CloseIcon from "src/assets/icons/Global/close.svg";

import IntroSection from "./components/IntroSection";
import JoinSection from "./components/JoinSection";
import CalculatorSection from "./components/CalculatorSection";
import DemocratizingSection from "./components/DemocratizingSection";
import DownloadSection from "./components/DownloadSection";
import SideBar from "src/components/SideBar";
import BackgroundLayer from "./components/BackgroundLayer";
import FullPageContainer from "./components/FullPageContainer";
import ModalRequirements from "../../components/ModalRequirements";

import { createRef, useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useIsMobile } from "src/hooks/useIsMobile";
import { useAppSelector } from "src/app/hooks";

const welcomePageSections = [
  {
    id: 0,
    name: "Intro",
    component: <IntroSection />,
    mobile: true,
  },
  {
    id: 1,
    name: "Democratizing",
    component: <DemocratizingSection />,
    mobile: true,
  },
  // {
  //   id: 2,
  //   name: "Calculator",
  //   component: <CalculatorSection />,
  //   mobile: false,
  // },
  {
    id: 2,
    name: "Download",
    component: <DownloadSection />,
    mobile: true,
  },
];

const WelcomePage = () => {
  const [activeSection, setActiveSection] = useState(0);
  const WelcomePageRef = useRef(null);
  const isMobile = useIsMobile(769);
  const isMobile2 = useIsMobile(1280);
  const { showModal } = useAppSelector((state) => state.Requirements);

  const { scrollYProgress } = useScroll({
    target: WelcomePageRef,
    offset: ["start start", "end end"],
  });

  const mapAnimation = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  const refs = welcomePageSections.reduce((refsObj, section) => {
    refsObj[section.name] = createRef();
    return refsObj;
  }, {});

  const handleClick = (name: string) => {
    refs[name].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <div className="home_page" ref={WelcomePageRef}>
      {/* Modals */}
      {showModal && <ModalRequirements />}

      {/* Side bar */}
      {!isMobile && (
        <SideBar
          welcomePageSections={welcomePageSections}
          activeSection={activeSection}
          handleClick={handleClick}
        />
      )}
      {/* Background layer */}
      <BackgroundLayer mapAnimation={mapAnimation} />
      {/* Page sections */}
      <div className="page-1">
        {welcomePageSections.map((sectionItem) => {
          if (!isMobile2) {
            return (
              <FullPageContainer
                sectionItem={sectionItem}
                refs={refs}
                key={sectionItem.id}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
              />
            );
          } else {
            if (sectionItem.mobile) {
              return (
                <FullPageContainer
                  sectionItem={sectionItem}
                  refs={refs}
                  key={sectionItem.id}
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                />
              );
            }
          }
        })}
      </div>
    </div>
  );
};

export default WelcomePage;
