import "./style.scss";

import HomeIcon from "src/assets/icons/JoinSection/optimized/home-ss.jpg";
import GameIcon from "src/assets/icons/JoinSection/optimized/game-ss.png";

import DownloadIcon from "src/assets/icons/DownloadSection/download.svg";

import DiscordLogo from "src/assets/icons/Social/discord.svg";
import TwitchLogo from "src/assets/icons/Social/twitch.svg";
import XComLogo from "src/assets/icons/Social/xcom.svg";

import { useEffect, useRef, useState } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import { motion, useAnimationControls } from "framer-motion";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useNavigate } from "react-router-dom";
import { toggleShowModal } from "src/features/RequirementsModal/requirementsSlice";

// Swiper
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Autoplay } from "swiper/modules";

gsap.registerPlugin(ScrollTrigger);

const DownloadSection = () => {
  const downloadContainer = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const buttonAnimation = useAnimationControls();
  const textAnimation = useAnimationControls();
  const previewAnimation = useAnimationControls();

  const { joinBtn } = useAppSelector((state) => state.Header);

  const handleClick = () => {
    navigate("/join");
  };

  const handleSystemRequirementsModal = () => {
    dispatch(toggleShowModal());
  };

  useEffect(() => {
    if (joinBtn === "joinSection") {
      window.scrollTo({
        top: downloadContainer.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [joinBtn]);

  // animations
  useEffect(() => {
    const currentEl = downloadContainer.current;
    const animation = gsap.to(currentEl, {
      scrollTrigger: {
        trigger: currentEl,
        start: "top center",
        end: "bottom center",
        onEnter: () => {
          buttonAnimation.start((custom) => ({
            scale: 1,
            opacity: 1,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          textAnimation.start((custom) => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.3 },
          }));
          previewAnimation.start((custom) => ({
            y: 0,
            opacity: 1,
            transition: {
              delay: custom * 0.3,
              type: "spring",
              damping: 11,
            },
          }));
        },
        onLeaveBack: () => {
          buttonAnimation.start((custom) => ({
            scale: 0.4,
            opacity: 0,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          textAnimation.start((custom) => ({
            y: 100,
            opacity: 0,
            transition: { delay: custom * 0.3 },
          }));
          previewAnimation.start((custom) => ({
            y: 100,
            opacity: 0,
            transition: {
              delay: custom * 0.3,
              type: "spring",
              damping: 11,
            },
          }));
        },
      },
    });

    return () => {
      animation.progress(0).kill();
    };
  }, []);

  return (
    <div className="download_section" id="download" ref={downloadContainer}>
      <div className="download_container">
        <motion.div
          initial={{ scale: 0.4, opacity: 0 }}
          custom={0}
          animate={buttonAnimation}
        >
          <button
            className="downloadBtn active-tap-animation"
            onClick={handleClick}
          >
            download
            <div className="icon">
              <img src={DownloadIcon} alt="" />
            </div>
          </button>
        </motion.div>
        <motion.p
          initial={{ opacity: 0, y: 100 }}
          animate={textAnimation}
          custom={1}
        >
          Check minimum system requirements:
          <span
            className="active-tap-animation"
            onClick={handleSystemRequirementsModal}
          >
            here
          </span>
        </motion.p>
      </div>
      <div className="preview_section">
        <motion.div
          className="preview_image"
          initial={{ opacity: 0, y: 100 }}
          animate={previewAnimation}
          custom={2}
        >
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            // pagination={{
            //   clickable: true,
            // }}
            modules={[EffectFade, Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={HomeIcon} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={GameIcon} alt="" />
            </SwiperSlide>
          </Swiper>

          <div className="footer">
            <section>
              <div className="icons-plh">
                <div className="icon active-tap-animation">
                  <img src={TwitchLogo} alt="" />
                </div>
                <div className="icon active-tap-animation">
                  <img src={XComLogo} alt="" />
                </div>
                <div className="icon active-tap-animation">
                  <img src={DiscordLogo} alt="" />
                </div>
              </div>
              <p>© {new Date().getFullYear()} TILTSTAR</p>
            </section>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DownloadSection;
