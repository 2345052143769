import "./style.scss";

import ArrowIcon from "src/assets/icons/WelcomePage/TestArrowSimple.svg";

const Mouse = () => {
  return (
    <div className="mouse_animation_container">
      <div className="mouse_animation">
        <div className="arrow">
          <img src={ArrowIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Mouse;
