import "./style.scss";
import axios from "axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { waitingListValidation } from "src/utils/yupValidation";
import { useEffect, useState } from "react";

import PageBox from "src/components/PageBox";
import Footer from "src/components/Footer";
import FormError from "src/components/FormError";
import SuccessBlock from "src/components/SuccessBlock";
import ErrorBlock from "src/components/ErrorBlock";
import { NavLink } from "react-router-dom";

const JoinPage = () => {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(waitingListValidation),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<string>("");
  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const errorMessage: any = formState.errors;

  const findUserLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/country");
      setUserLocation(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const joinData = {
      email: data.email,
      countryCode: userLocation,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        "https://raxvn578q5.execute-api.us-east-1.amazonaws.com/test/wait-list",
        joinData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      console.log(response.data.statusCode);
      if (response.data.statusCode === 400) {
        setRequestErrorMessage(response.data.body.message);
        setTimeout(() => {
          setRequestErrorMessage("");
        }, 3500);
      } else {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3500);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setRequestErrorMessage(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    reset({ email: "" });
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
    findUserLocation();
  }, []);

  return (
    <div className="join_page">
      <div className="background_gradient"></div>
      <div className="background_gradient_overlay"></div>
      <div className="content">
        <PageBox>
          <div className="content-wrapper">
            <h1>Be among the first to try Tiltstar</h1>
            {/* <h3>
              Join Blake, Zack and <span>2.352</span> others for exclusive
              offers and rewards.
            </h3> */}
            <h3>Join now for exclusive offers and rewards. </h3>
            {isSuccess && (
              <SuccessBlock message="Thanks for joining our waiting list!" />
            )}
            {requestErrorMessage && (
              <ErrorBlock message={requestErrorMessage} />
            )}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <section>
                {/* <label className="input_plh">
                  <input {...register("name")} type="input" placeholder="Name" />
                  {errorMessage.name && (
                    <FormError message={errorMessage.name?.message} />
                  )}
                </label> */}
                <label className="input_plh">
                  <input
                    {...register("email")}
                    type="email"
                    placeholder="Email"
                  />
                  {errorMessage.email && (
                    <FormError message={errorMessage.email?.message} />
                  )}
                </label>
                {/* {isSuccess && <SuccessBlock />} */}
              </section>
              <button
                className={
                  errorMessage.email || requestErrorMessage
                    ? "btn_blocked btn"
                    : "btn active-tap-animation"
                }
                type="submit"
                disabled={
                  errorMessage.email || requestErrorMessage ? true : false
                }
              >
                <p>{loading ? "Loading..." : "Join"}</p>
              </button>
            </form>
            <div className="agreement">
              By signing up, you will receive emails about Tiltstar™ and you
              agree to our
              <div className="active-tap-animation">
                <NavLink to="/terms">Terms & Conditions.</NavLink>
              </div>
            </div>
          </div>
          <Footer />
        </PageBox>
      </div>
    </div>
  );
};

export default JoinPage;
