import "./style.scss";

import DiscordLogo from "src/assets/icons/Social/dark/discord.svg";
import TwitchLogo from "src/assets/icons/Social/dark/twitch.svg";
import XComLogo from "src/assets/icons/Social/dark/xcom.svg";

import { useAppSelector } from "src/app/hooks";
import { useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  // const { footerVisibility } = useAppSelector(
  //   (state) => state.ElementsVisibility
  // );
  // const footerAnimation = useAnimationControls();
  // const location = useLocation();

  // useEffect(() => {
  //   if (footerVisibility && location.pathname === "/") {
  //     footerAnimation.start((custom) => ({
  //       y: 0,
  //       opacity: 1,
  //       transition: { delay: custom * 0.3 },
  //     }));
  //   } else if (location.pathname !== "/") {
  //     footerAnimation.start((custom) => ({
  //       y: 0,
  //       opacity: 1,
  //       transition: { duration: 0 },
  //     }));
  //   } else {
  //     footerAnimation.start((custom) => ({
  //       y: 100,
  //       opacity: 0,
  //     }));
  //   }
  // }, [footerVisibility, location.pathname]);

  const currentYear = new Date().getFullYear();

  return (
    <motion.footer
      // initial={{ opacity: 0, y: 100 }}
      // animate={footerAnimation}
      // custom={1.5}
      className={location.pathname === "/" ? "" : "other_pages"}
    >
      <div className="icons-plh">
        <div className="icon active-tap-animation">
          <img src={TwitchLogo} alt="" />
        </div>
        <div className="icon active-tap-animation">
          <img src={XComLogo} alt="" />
        </div>
        <div className="icon active-tap-animation">
          <img src={DiscordLogo} alt="" />
        </div>
      </div>
      <p className="copyright">© {currentYear} TILT STAR</p>
    </motion.footer>
  );
};

export default Footer;
