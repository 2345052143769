import { useEffect } from "react";
import Footer from "src/components/Footer";
import PageBox from "src/components/PageBox";
import "./style.scss";

const TermsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms_page">
      <div className="background_gradient"></div>
      <div className="background_gradient_overlay"></div>
      <div className="content">
        <PageBox>
          {/* Page content */}
          <div className="flex flex-col">
            <h1>Terms and Conditions</h1>
            <p className="simple-ft">
              These Terms govern the relationship between Tiltstar BV (hereafter
              also referred to as “Tiltstar”) and you as a user of services or
              products provided by Tiltstar (hereafter also referred to as
              “Player”).
            </p>
            <p className="intrro-ft">General Terms</p>
            <p className="simple-list-ft">
              <span className="ft">1.1. Player accounts </span> The use of the
              services and products provided by Tiltstar requires prior
              registration of an account as a Player, which registration has to
              be accepted by Tiltstar. Tiltstar reserves the right to reject
              Player registrations without specifying any reason for rejection.
              Multiple registrations by a single person, as well as the setting
              up and operation of multiple accounts at Tiltstar are strictly
              prohibited. Player Accounts are non-transferable.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.2. Types of Players </span> Tiltstar
              distinguishes between users who are Practice Players and that are
              Money Players. Practice Players only use non redeemable gaming
              credits whilst Money Players are able to participate in games
              using, depositing, playing with, or withdrawing actual money.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.3. Username, Password</span>
              For security reason’s usernames may not be derived either from
              e-mail addresses or include a domain name, they may not infringe
              upon the rights of third parties, and may not violate any rules of
              good conduct and public propriety. The Player must choose a secure
              password. Tiltstar reserves the right to at any time change or add
              new requirements for the choosing of passwords. At minimum, a
              secure password must contain a combination of numbers, letters
              and, when possible, special characters. Passwords must not be
              shared, but kept secret and periodically changed every 2 months at
              the latest. If a third party gains access to the account of a user
              who is in violation of these terms or Tiltstar security guidelines
              and plays games or withdraws funds, then the user shall bear any
              and all expenses incurred and Tiltstar will not be liable to that
              user for any form of compensation. Tiltstar will not disclose your
              password to any third parties unless are required to do so by law.
              Tiltstar will never ask Players for their passwords via telephone
              or E-mail.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.4. Data Entry</span>
              The personal information that Tiltstar requests upon registration
              is to be fully and accurately completed. These may include a valid
              e-mail address, username, password, first and last name, date of
              birth, address, bank information (when applicable), mobile phone
              number and home telephone number (if available), as well as data
              from a valid ID or Passport (when applicable). Age verification
              can be automatically carried out upon completion of registration
              based on the information entered, but may be subject to further
              presentation of proof. In the event that false or incorrect data
              is entered Tiltstar reserves the right to immediately terminate a
              Player's Account on Tiltstar, withhold any prize money and/or
              cancel any account withdrawal. When the entered personal data
              change the concerned Player is obliged to immediately update,
              change, or notify Tiltstar of the changes. E-mails may be sent to
              help@tiltstar.com
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.5. Privacy</span>
              We take the protection of personal data seriously. The use of such
              data will take place regularly in order to enable you to play the
              games, find you the right gaming match, correspond with you, to
              alert you to any new games that we think might be of interest to
              you, to alert you to any changes to these Terms of Services, and
              in order to perform necessary services, and bill you when if
              applicable. In doing so, Tiltstar adheres to its Privacy Policy,
              which is applicable here by reference.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.6. Gameplay Requirements</span>
              Players with an accepted Account have the right to use Tiltstar.
              Certain games or certain features offered therein may be
              conditional to a certain Player status, age requirement, proof of
              payment, shown skill level (amount of completed games), specific
              game rules, and other factors Tiltstar may find relevant. Players
              are solely responsible to ensure that their hardware devices,
              software, and internet connections are up-to-date, functioning and
              in step with the necessary System Requirements, which will be set
              out for each particular game by Tiltstar. A Player’s right to use
              Tiltstar products and its features is conditional to the current
              technical status of Tiltstar platforms at any given time.
              Temporary disturbances may be experienced due to the interruption
              of telecommunications, power outages, hardware and software
              failures, etc. Maintenance work is conducted mainly for the
              purpose of ensuring the server's security and integrity. If
              possible, Players shall be notified of maintenance periods in
              advance. Games that have begun prior to such maintenance periods
              will not be extended, despite the fact that no game play can take
              place during maintenance periods.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.7. Fundamental Rules of Use</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.7.1. Abuse</span>
              Abusive behavior is prohibited. Such behavior includes, but is not
              limited to:
              {/* list */}
              <ul>
                <li>
                  <span className="ft">1. </span>
                  Intentionally entering faulty, misleading, incorrect, or
                  incomplete information;
                </li>
                <li>
                  <span className="ft">2. </span>
                  Opening multiple Player Accounts or opening Player Accounts
                  without proper authorization;
                </li>
                <li>
                  <span className="ft">3. </span>
                  Committing, or attempting to commit fraud, especially but not
                  limited through the use of mechanisms, software, or any script
                  in association with the Website that is intended to interrupt
                  the functioning of the Website, help a user achieve better
                  results than could be achieved through the sole use of regular
                  game controls , help the user to achieve a score that is not
                  possible by only following these controls described by
                  Tiltstar, or negatively influence another Player's game
                  controls;
                </li>
                <li>
                  <span className="ft">4. </span>
                  Purposely causing an interruption or error in order to
                  influence a game, such as to cause the game to prematurely
                  end;
                </li>
                <li>
                  <span className="ft">5. </span>
                  Achieving or attempting to achieve an advantage over other
                  Players through unfair behavior of any kind, such as through
                  deception, which could damage or endanger a fair competition;
                </li>
                <li>
                  <span className="ft">6. </span>
                  Cooperating with other Players in a way that could create an
                  advantage over novice users and thus diminish their
                  opportunity for fair play and reduce their chances of winning.
                </li>
                <li>
                  <span className="ft">7. </span>
                  Breaking any further rules or duties that are detailed in the
                  general Terms of Services.
                </li>
              </ul>
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.7.2. Communication</span>
              When it is possible for Players to contact each other, for reasons
              such as challenging each other to Matches. In doing so, they are
              obligated to only send messages that:
              <ul>
                <li>
                  <span className="ft">1. </span>
                  Are free of obvious or hidden advertisement;
                </li>
                <li>
                  <span className="ft">2. </span>
                  Are neither subjective, intentionally false, derogatory,
                  defamatory, immoral, nor pornographic in nature nor fulfill
                  any other element of offense;
                </li>
                <li>
                  <span className="ft">3. </span>
                  Are not damaging to any third parties;
                </li>
                <li>
                  <span className="ft">4. </span>
                  Do not contain any viruses or computer programs that could
                  affect the function of external computers;
                </li>
                <li>
                  <span className="ft">5. </span>
                  Are not surveys or chain letters;
                </li>
                <li>
                  <span className="ft">6. </span>
                  Were not written with the intent to expand a certain
                  political, ideological, or religious denomination;
                </li>
                <li>
                  <span className="ft">7. </span>
                  Were not written with the intent to collect, save, or use
                  personal information from users without their explicit
                  consent, especially for promotional purposes;
                </li>
              </ul>
            </p>
            <p className="simple-list-ft">
              <span className="ft">
                1.8. Cancellation / Contract Duration and Termination
              </span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.8.1. Termination</span>A Player can
              terminate his Account and thereby any user license agreement at
              any time during the course of participation. All that is required
              to terminate is a written announcement sent to help@tiltstar.com.
              A remaining positive balance on the Player Account will be
              transferred to his/her bank account within a processing period of
              up to 30 workdays. If his bank information is unknown, then the
              general conditions under section 3.2.2 of these Terms of Service
              will be applied. Tiltstar has the right to terminate a Player’s
              Account with two weeks’ notice but may terminate and deactivate
              Player’s accounts for substantial / or pressing reasons, including
              but not limited in cases of abusive behavior or the suspicion of
              abuse, especially those detailed in section 1.7.1 and 1.7.2. If
              such abuse is likely, then games which have already begun may be
              aborted and the results will not be evaluated. In case of abuse
              the Player is obliged to compensate and indemnify Tiltstar for any
              caused loss, damage, or third party legal claims, including tax
              claims.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.9. Liability</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.9.1. Limitation</span>
              Tiltstar is not liable for loss, damage, or injury caused by
              access, use, or attempted access or use of its platforms,
              websites, applications. Tiltstar is also not liable for any loss,
              damage or injury caused by downloading any data or for information
              obtained in any other way from Tiltstar unless the problems are
              caused by wanton neglect or purposeful breach of duty on the part
              of Tiltstar. Tiltstar shall only be held liable for its vicarious
              agents and/or legal representatives who have acted with wanton
              negligence or purposeful breach of duty and as long as no material
              contractual obligations have been violated by Tiltstar, its
              vicarious agents and/or legal representatives. Damage resulting
              from injury to life, body or health shall not be affected
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.9.3. Abuse</span>
              Tiltstar is not liable for any damages of any kind that are caused
              due to a Player’s misuse of Tiltstar services, which include all
              resulting losses by any third parties.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.9.4. Match abort</span>
              If Tiltstar is forced to close a Match (as hereafter defined)
              prematurely, then the participating Players can have their entry
              fees refunded and credited to their Player Accounts. Tiltstar is
              not liable for any collateral or consequential damages.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.9.5. Losses</span>
              Tiltstar is not liable for lost winnings or lost potential
              winnings, not even in the case of prematurely closed matches.
              Tiltstar is not liable for any taxes, levies, duties or similar
              charges with regard to the Players winnings except to the extent
              required by law. The Player is solely responsible for reporting
              his winnings and losses arising from the use of Tiltstar and it is
              his sole responsibility to pay and proceed with all necessary
              diligence in relation to taxes as required by law or other
              authorities.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.9.6. Scope of content</span>
              Tiltstar shall be accountable for the content that it makes
              available. Tiltstar cannot be held accountable for offers from
              third-parties for which we only offer access (such as via links).
              We expressly call to your attention that we cannot be held liable
              for any content on third-party websites for which we offer links.
            </p>
            <p className="simple-list-ft">
              <span className="ft">
                1.10. Amendments to These Terms of Service
              </span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.10.1. Notification</span>
              All declarations that are conducted within the context of the
              Tiltstar license agreement must be made in written form or via
              e-mail. The Tiltstar e-mail address is help@tiltstar.com.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.10.2. Revisions</span>
              Tiltstar reserves the right to revise these Terms of Service from
              time to time. In such an event, Tiltstar will communicate the fact
              that Terms of Service have been revised and will apply to any new
              gameplay, and ask the Player to confirm acceptance of these terms
              electronically, making these tems available for prior review. If a
              Player rejects the newly revised Terms of Service, then Tiltstar
              may terminate his account in accordance with section 1.8.2.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.11. Sever ability</span>
              If any section of these terms and agreements is deemed invalid,
              then the remaining sections and agreements will remain valid and
              enforceable. If this is the case, the most similar provision
              possible shall be added at our economic convenience, which shall
              make the illegal, invalid, or unenforceable provisions legal,
              valid and enforceable.
            </p>
            <p className="simple-list-ft">
              <span className="ft">1.14. Foreign regulation</span>
              If you are unsure of whether Tiltstar's services are legally
              permitted in your area, then seek legal advice prior to
              registration and/or to participation. Tiltstar Players are solely
              responsible for following all applicable laws in their country
              and/or state. In the event that a Player acts illegally by using
              our services despite local limits on or prohibition of such use,
              then no user contract shall take effect. Tiltstar cannot be held
              accountable for legal matters arising from laws in your area, and
              will cooperate with local government agencies of the area if
              necessary.
            </p>
            <p className="intrro-ft">Terms for Money-Players</p>
            <p className="simple-list-ft">
              <span className="ft">3.1. Registration</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.1.1 Registration as a Money Player</span>
              Participation as a Money Player requires submitting certain
              personal information, and the payment of a minimum deposit onto
              your account.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.1.2. Age Verification</span>
              Only contractually capable, natural persons are allowed to
              register as Money Players. Persons under the age of 18 are not
              permitted to participate in the games in which you have the chance
              to win money. Verification of age verification can be
              automatically carried out upon completion of registration, based
              on the information entered. In order to comply with youth
              protection regulations, Tiltstar may ask for additional proof to
              verify age and non compliance within a reasonable time frame is
              ground for immediate termination of the Player account, or ground
              to bar that Player from being active as a Money Player.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.1.3. Staff Participation</span>
              Staff of Tiltstar and their associate companies are not eligible
              to participate as Money Players on Tiltstar. They may, however,
              participate in games for testing and marketing purposes, but will
              surrender any gain or profit to Tiltstar.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.2 Player Accounts</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.2.1. Deposit</span>
              The opening of a Player Account is free of charge, and requires
              that Tiltstar has received payment of the minimum deposit and the
              user has completed a Money Player registration. Deposited funds
              can be used exclusively to play games at Tiltstar, and to cover
              Tiltstar fees and commission. Deposited funds can be withdrawn in
              accordance with sections 3.2.2 through 3.2.6.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.2.2. Withdrawal</span>
              Money players are granted the right to withdraw their positive
              bank account, or any portion of it, the minimal amount the money
              player can withdraw is 10 USD. Any incurred fees will first be
              offset from the balance of the Player. Tiltstar is not obligated
              but is at liberty to verify the authorization of the account
              holder by requiring additional identification. The withdrawal
              shall be carried out during 10-20 days after the request is
              processed.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.2.5. Proof of Identity</span>
              For security reasons, Tiltstar is authorized to withhold funds
              until proof of identity can be confirmed. In cases such as these,
              the user shall be obligated to send a copy his personal ID or
              passport, along with a written request to withdraw the funds,
              including his bank information.
            </p>
            <p className="simple-list-ft">
              <span className="ft">
                3.2.6. Deletion of Money Player Accounts
              </span>
              Money Player Accounts will be deleted at our sole discretion and
              without any notification of reason once a period of 2years has
              passed since the last login or when the contract is terminated.
              The agreement between Player and Tiltstar shall be severed along
              with the deletion of the Player Account. If the Player replies
              with bank account information that does not deviate from what was
              previously submitted, then funds on the account in question, if
              any, will be transferred to the last bank account that was
              associated with that Member's Player Account. If a Player Account
              is deleted due to substantial reasons, then Tiltstar shall reserve
              the right to reserve funds for the purpose of compensation for
              Tiltstar or third-parties, the then current balance of the account
              will be considered liquidated damages due to Tiltstar for a breach
              of contract.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.3. Entry Fees and Prizes</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.3.1. Match Entry Fees</span>
              By participating in a particular match or tournament (“Match”),
              the Player authorizes Tiltstar to deduct the indicated fee (“Entry
              Fee”) from his Player Account, and to credit that Entry Fee to the
              account of a Player that Tiltstar has determined to be the Match
              winner, after deduction of a handling fee due to Tiltstar
              (“Service Fee”). The Entry Fee will be deducted from the Member's
              Player Account upon entering the Match, and is non-refundable. A
              Player cannot enter a Match if sufficient funds are not available
              in his Player Account. The Service Fee due to Tiltstar amounts to
              fifteen percent (10%) of each Entry Fee unless otherwise
              indicated. Tiltstar reserves the right to place a limit on the
              total Entry Fee(s) allowed per Match, or per Player, whether
              equally for all Players, or for a particular Player.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.3.3. Prize Money</span>
              The potential prize money is displayed at the beginning of each
              Match save for in Matches with high, progressive winnings where
              the sum of the potential prize money is dependent upon the sum of
              participants and their frequency of participation.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.3.4. Aborted Games</span>
              If a Player aborts a game, either purposefully or due to technical
              problems that cannot be attributed to Tiltstar, then the score
              that was last transmitted to Tiltstar will be counted as the
              result.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.3.6. Taxes</span>
              In case of withdrawal of prize money Tiltstar may withhold any
              taxes, levies, duties or similar charges or claims caused by the
              Player and, if charged to pay for such charges or claims, may set
              these off against the Player’s deposit, without any prejudice to
              the obligations of the Player, who remains solely responsible to
              proceed with all necessary diligence in relation to taxes, and pay
              such taxes, etc., as required by law or other authorities.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.4. Deposit and Fees</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">
                3.4.2. Deposits via other Methods of Payment
              </span>
              Tiltstar is revocable authorized, but not obligated, to accept
              payments via credit card, Direct Debit, and other forms of
              payment. Along with each Direct Debit that is set up, the user
              authorizes Tiltstar to deduct the specified funds from the
              submitted bank account. The user irrevocably agrees that Tiltstar
              may inform the user's financial institution (or any agency that is
              commissioned by Tiltstar to collect any outstanding funds) of the
              user's complete name, date of birth, and address if the user's
              direct debit cannot be honored. Furthermore, the user agrees that
              his financial institution (or any agency that is commissioned by
              Tiltstar to collect any outstanding funds) may be transferred
              Tiltstar's information, which may also be saved for the purpose of
              processing the Direct Debit until the claim has been met.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.5. Remedies in case of Abuse</span>
            </p>
            <p className="simple-list-ft">
              <span className="ft">
                3.5.1 Termination, indemnification and penalties
              </span>
              Tiltstar is authorized to close and/or delete Player Accounts and
              end this contractual agreement without notice in cases of abusive
              behavior or the suspicion of abuse, especially those detailed in
              section 1.7.1 and 1.7.2. If such abuse is likely, then games which
              have already begun may be aborted and the results will not be
              evaluated. In case of abuse the Player is obliged to compensate
              and indemnify Tiltstar for any caused loss or damage or third
              party legal claims, including tax claims.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.5.2. Further measures against Abuse</span>
              In accordance with section 3.5.1., Tiltstar reserves the right to
              block or delete user accounts, and release information to legal
              courts and government agencies in the event of abusive content,
              statements, etc. Furthermore, Tiltstar reserves the right to file
              a report with the police.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.6. Reporting Problems</span>
              All problems encountered during the use of our services, including
              those with regard to games, Player Accounts, etc., can be reported
              to Tiltstar of when the problem is encountered. Tiltstar deletes
              usage data after use, and after 90 days in accordance with data
              protection regulations. Because of this, problems and errors can
              then no longer be reviewed.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.7. Posts / Comments</span>
              Tiltstar is granted the unlimited right of usage in terms of space
              and time for all posts submitted on the Tiltstar user forum, or
              other information submitted to Tiltstar using the provided
              mechanisms for such including photographs, game comments,
              suggestions, etc. The user is not entitled to compensation for
              such.
            </p>
            <p className="simple-list-ft">
              <span className="ft">3.8. Public Relations</span>
              As a Money Player, you consent to the use of your username,
              photographs and recordings to be displayed along with the amount
              of prize money you have won for the purpose of advertisement,
              press releases, release in printed media, radio, television,
              Internet, and otherwise be publicly made available, used, and
              duplicated if you win more than 50 EUR in a single month. In the
              event of live or webcam games or tournament events, then you also
              consent to the publication and duplication of this data,
              recordings, and photographs for the purpose of press releases,
              advertisement, publications, public access, utilization and
              duplication. You have no right to compensation for such use of
              this data. Please direct all complaints, problems, and comments at
              help@tiltstar.com.
            </p>
          </div>
          {/* Footer */}
          <Footer />
        </PageBox>
      </div>
    </div>
  );
};

export default TermsPage;
