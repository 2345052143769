import { Route, Routes } from "react-router-dom";
import "./assets/index.scss";

import Layout from "./components/Layout";
import TermsPage from "./pages/TermsPage";
import WelcomePage from "./pages/WelcomePage";
import { AnimatePresence } from "framer-motion";
import ContactPage from "./pages/ContactPage";
import JoinPage from "./pages/JoinPage";
import { useEffect, useState } from "react";

function App() {
  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<WelcomePage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="join" element={<JoinPage />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default App;
