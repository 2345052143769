import "./style.scss";

import CloseIcon from "src/assets/icons/Global/close.svg";

import { toggleShowModal } from "src/features/RequirementsModal/requirementsSlice";
import { useAppDispatch } from "src/app/hooks";
import { useEffect } from "react";

const requirementsData = {
  minimum: [
    {
      id: 1,
      title: "OS",
      requirement: "Windows 7",
    },
    {
      id: 2,
      title: "Processor",
      requirement: "Intel Core i3 or equivalent",
    },
    {
      id: 3,
      title: "Memory",
      requirement: "4 GB RAM",
    },
    {
      id: 4,
      title: "Storage",
      requirement: "5 GB available space",
    },
    {
      id: 5,
      title: "Direct X",
      requirement: "Version 11",
    },
    {
      id: 6,
      title: "Graphics",
      requirement: "Nvidia GeForce GTX 550 Ti or equivalent",
    },
    {
      id: 7,
      title: "Other",
      requirement: "For Low graphics quality at 720p",
    },
  ],
  recommended: [
    {
      id: 1,
      title: "OS",
      requirement: "Windows 10",
    },
    {
      id: 2,
      title: "Processor",
      requirement: "Intel Core i3 or equivalent",
    },
    {
      id: 3,
      title: "Memory",
      requirement: "16 GB RAM",
    },
    {
      id: 4,
      title: "Storage",
      requirement: "5 GB available space",
    },
    {
      id: 5,
      title: "Direct X",
      requirement: "Version 12",
    },
    {
      id: 6,
      title: "Graphics",
      requirement: "Nvidia GeForce GTX 1060 or equivalent",
    },
    {
      id: 7,
      title: "Other",
      requirement: "For High graphics quality at 1080p",
    },
  ],
};

const ModalRequirements = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(toggleShowModal());
  };

  useEffect(() => {
    document.body.classList.add("body-scroll-hidden");
    document.documentElement.classList.add("body-scroll-hidden");

    return () => {
      document.body.classList.remove("body-scroll-hidden");
      document.documentElement.classList.remove("body-scroll-hidden");
    };
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="content-wrapper">
        <div className="header">
          <h2>system requirements</h2>
          <button
            onClick={handleClick}
            className="close-btn active-tap-animation"
          >
            <img src={CloseIcon} alt="" />
          </button>
        </div>
        <div className="content">
          {/* Minimum */}
          <section>
            <h3>minimum</h3>
            <div className="requirements-wrapper">
              {requirementsData.minimum.map((requirement) => {
                return (
                  <div className="requirement" key={requirement.id}>
                    {requirement.title}
                    <span>{requirement.requirement}</span>
                  </div>
                );
              })}
            </div>
          </section>
          {/* Recommended */}
          <section>
            <h3>recommended</h3>
            <div className="requirements-wrapper">
              {requirementsData.recommended.map((requirement) => {
                return (
                  <div className="requirement" key={requirement.id}>
                    {requirement.title}
                    <span>{requirement.requirement}</span>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ModalRequirements;
