import "./style.scss";

import { useEffect, useRef } from "react";
import { motion, useAnimationControls } from "framer-motion";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import BigIcon from "src/assets/icons/IntroSection/optimized/players.png";

import Mouse from "src/components/Mouse";
gsap.registerPlugin(ScrollTrigger);

const IntroSection = () => {
  const introContainer = useRef<HTMLDivElement>(null);

  const bigIconAnimation = useAnimationControls();
  const h1Animation = useAnimationControls();
  const ElementsYAnimation = useAnimationControls();

  useEffect(() => {
    const currentEl = introContainer.current;
    const animation = gsap.to(currentEl, {
      scrollTrigger: {
        trigger: currentEl,
        start: "top center",
        end: "bottom center",
        onEnter: () => {
          bigIconAnimation.start({
            scale: 1,
            opacity: 1,
            transition: { duration: 0.6 },
          });
          h1Animation.start((custom) => ({
            scale: 1,
            opacity: 1,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          ElementsYAnimation.start((custom) => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.3 },
          }));
        },
        onLeave: () => {
          bigIconAnimation.start({
            scale: 1.5,
            opacity: 0,
            transition: { duration: 0.6 },
          });
          h1Animation.start((custom) => ({
            scale: 0.4,
            opacity: 0,
          }));
          ElementsYAnimation.start((custom) => ({
            y: 100,
            opacity: 0,
          }));
        },
        onEnterBack: () => {
          bigIconAnimation.start({
            scale: 1,
            opacity: 1,
            transition: { duration: 0.6 },
          });
          h1Animation.start((custom) => ({
            scale: 1,
            opacity: 1,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          ElementsYAnimation.start((custom) => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.3 },
          }));
        },
      },
    });

    return () => {
      animation.progress(0).kill();
    };
  }, []);

  return (
    <div className="intro_section" ref={introContainer}>
      <motion.div
        initial={{ scale: 1.5, opacity: 0, zIndex: 0 }}
        animate={bigIconAnimation}
        className="big_icon"
      >
        <img src={BigIcon} alt="" />
      </motion.div>
      <article>
        <section>
          <motion.h1
            initial={{ scale: 0.4, opacity: 0 }}
            custom={0}
            animate={h1Animation}
          >
            Where
          </motion.h1>

          <motion.h1
            initial={{ scale: 0.4, opacity: 0 }}
            custom={0.3}
            animate={h1Animation}
          >
            legends
          </motion.h1>

          <motion.h1
            initial={{ scale: 0.4, opacity: 0 }}
            custom={0.6}
            animate={h1Animation}
          >
            are
          </motion.h1>

          <motion.h1
            initial={{ scale: 0.4, opacity: 0 }}
            custom={0.9}
            animate={h1Animation}
          >
            born
          </motion.h1>
        </section>
        <motion.p
          initial={{ opacity: 0, y: 100 }}
          animate={ElementsYAnimation}
          custom={1}
        >
          Earn by playing & competing in battle royale games
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={ElementsYAnimation}
          custom={2}
          className="mouse_plh"
        >
          <Mouse />
        </motion.div>
      </article>
    </div>
  );
};

export default IntroSection;
