import "./style.scss";
import axios from "axios";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactValidation } from "src/utils/yupValidation";
import { useEffect, useState } from "react";

import PageBox from "src/components/PageBox";
import Footer from "src/components/Footer";
import FormError from "src/components/FormError";
import SuccessBlock from "src/components/SuccessBlock";
import ErrorBlock from "src/components/ErrorBlock";

import WavingIcon from "src/assets/icons/Global/waving-emoji.png";

interface IContactData {
  name: string;
  email: string;
  message: string;
}

const ContactPage = () => {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(contactValidation),
  });
  const [textCopied, setTextCopied] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<string>("");
  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const errorMessage: any = formState.errors;

  const findUserLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/country");
      setUserLocation(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const contactData = {
      email: data.email,
      message: data.message,
      // countryCode: userLocation,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        "https://raxvn578q5.execute-api.us-east-1.amazonaws.com/test/contact",
        contactData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      console.log(response.data.statusCode);
      if (response.data.statusCode === 400) {
        setRequestErrorMessage(response.data.body.message);
        setTimeout(() => {
          setRequestErrorMessage("");
        }, 3500);
      } else {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3500);
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setRequestErrorMessage(error);
      setLoading(false);
    }
  };

  const handleCopyToClipboard = async (textToCopy) => {
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("value", textToCopy);
    document.body.appendChild(hiddenInput);
    hiddenInput.select();
    document.execCommand("copy");
    document.body.removeChild(hiddenInput);
    setTextCopied(true);
  };

  useEffect(() => {
    reset({ email: "", message: "" });
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
    findUserLocation();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTextCopied(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [textCopied]);

  return (
    <div className="contact_page">
      <div className="background_gradient"></div>
      <div className="background_gradient_overlay"></div>
      <div className="content">
        <PageBox>
          <div className="content-wrapper">
            <h1>Contact us</h1>
            {isSuccess && (
              <SuccessBlock message="Thanks for contacting us. We will be in touch soon!" />
            )}
            {requestErrorMessage && (
              <ErrorBlock message={requestErrorMessage} />
            )}
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <section>
                {/* <label className="input_plh">
                  <input
                    {...register("name")}
                    type="input"
                    placeholder="Name"
                  />
                  {errorMessage.name && (
                    <FormError message={errorMessage.name?.message} />
                  )}
                </label> */}
                <label className="input_plh">
                  <input
                    {...register("email")}
                    type="email"
                    placeholder="Email"
                  />
                  {errorMessage.email && (
                    <FormError message={errorMessage.email?.message} />
                  )}
                </label>
                <label className="input_plh">
                  <textarea
                    {...register("message")}
                    placeholder="Message"
                  ></textarea>
                  {errorMessage.message && (
                    <FormError message={errorMessage.message?.message} />
                  )}
                </label>
              </section>
              <button
                className={
                  errorMessage.email || errorMessage.message
                    ? "btn_blocked btn active-tap-animation"
                    : "btn active-tap-animation"
                }
                type="submit"
                disabled={
                  errorMessage.email || errorMessage.message ? true : false
                }
              >
                <p>{loading ? "Loading..." : "Submit"}</p>
              </button>
            </form>
            <p className="or">OR</p>
            <button
              className="clipboardBtn"
              style={{ WebkitTapHighlightColor: "transparent" }}
              onClick={() => handleCopyToClipboard("contact@tiltstar.com")}
            >
              <span>
                <img src={WavingIcon} alt="" />
              </span>
              <p>{textCopied ? "email copied" : "email us"}</p>
            </button>
          </div>
          <Footer />
        </PageBox>
      </div>
    </div>
  );
};

export default ContactPage;
