import "./styles.scss";

import { Outlet } from "react-router-dom";
import Header from "../Header";
import { useState } from "react";
import ModalNavigation from "../ModalNavigation";
import { useIsMobile } from "src/hooks/useIsMobile";

const Layout = () => {
  const isMobile = useIsMobile(769);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onModalToggle = () => {
    setIsOpen(!isOpen);
    console.log("clicked");
  };

  return (
    <div className="main">
      <Header onModalToggle={onModalToggle} />
      <div className="body">
        <Outlet />
      </div>
      {isMobile && (
        <ModalNavigation isOpen={isOpen} onModalToggle={onModalToggle} />
      )}
    </div>
  );
};

export default Layout;
