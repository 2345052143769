import "./style.scss";
import ErrorIcon from "src/assets/icons/Global/attention-red.svg";

const FormError = ({ message }) => {
  return (
    <div className="error_plh">
      <p>{message}</p>
      <div className="icon">
        <img src={ErrorIcon} alt="" />
      </div>
    </div>
  );
};

export default FormError;
