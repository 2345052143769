import { createSlice } from "@reduxjs/toolkit";

interface IInitialState {
  showModal: Boolean;
}

const initialState: IInitialState = {
  showModal: false,
};

export const requirementsSlice = createSlice({
  name: "affiliate",
  initialState,

  reducers: {
    toggleShowModal: (state) => {
      state.showModal = !state.showModal;
    },
  },
});

export const { toggleShowModal } = requirementsSlice.actions;

export default requirementsSlice.reducer;
