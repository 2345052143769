import "./style.scss";

import { useEffect, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
import { motion, useAnimationControls } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

const DemocratizingSection = () => {
  const democratizingContainer = useRef<HTMLDivElement>(null);

  const h1Animation = useAnimationControls();
  const factRightAnimation = useAnimationControls();
  const factLeftAnimation = useAnimationControls();
  const ElementsYAnimation = useAnimationControls();

  useEffect(() => {
    const currentEl = democratizingContainer.current;
    const animation = gsap.to(currentEl, {
      scrollTrigger: {
        trigger: currentEl,
        start: "top center",
        end: "bottom center",
        onEnter: () => {
          h1Animation.start((custom) => ({
            scale: 1,
            opacity: 1,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          ElementsYAnimation.start((custom) => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.3 },
          }));
          factRightAnimation.start((custom) => ({
            x: 0,
            opacity: 1,
            transition: { delay: custom * 0.2 },
          }));

          factLeftAnimation.start((custom) => ({
            x: 0,
            opacity: 1,
            transition: { delay: custom * 0.2 },
          }));
        },
        onLeave: () => {
          h1Animation.start((custom) => ({
            scale: 0.4,
            opacity: 0,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          ElementsYAnimation.start((custom) => ({
            y: 100,
            opacity: 0,
          }));
          factRightAnimation.start((custom) => ({
            x: 200,
            opacity: 0,
            transition: { delay: custom * 0.2 },
          }));
          factLeftAnimation.start((custom) => ({
            x: -200,
            opacity: 0,
            transition: { delay: custom * 0.2 },
          }));
        },
        onEnterBack: () => {
          h1Animation.start((custom) => ({
            scale: 1,
            opacity: 1,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          ElementsYAnimation.start((custom) => ({
            y: 0,
            opacity: 1,
            transition: { delay: custom * 0.3 },
          }));
          factRightAnimation.start((custom) => ({
            x: 0,
            opacity: 1,
            transition: { delay: custom * 0.2 },
          }));
          factLeftAnimation.start((custom) => ({
            x: 0,
            opacity: 1,
            transition: { delay: custom * 0.2 },
          }));
        },
        onLeaveBack: () => {
          h1Animation.start((custom) => ({
            scale: 0.4,
            opacity: 0,
            transition: {
              delay: custom,
              type: "spring",
              damping: 11,
            },
          }));
          ElementsYAnimation.start((custom) => ({
            y: 100,
            opacity: 0,
          }));
          factRightAnimation.start((custom) => ({
            x: 200,
            opacity: 0,
            transition: { delay: custom * 0.2 },
          }));
          factLeftAnimation.start((custom) => ({
            x: -200,
            opacity: 0,
            transition: { delay: custom * 0.2 },
          }));
        },
      },
    });

    return () => {
      animation.progress(0).kill();
    };
  }, []);

  return (
    <div className="democratizing_section" ref={democratizingContainer}>
      <article>
        <section>
          <motion.h1
            initial={{ scale: 0.4, opacity: 0 }}
            custom={0}
            animate={h1Animation}
          >
            Democratizing
          </motion.h1>
          <motion.h1
            initial={{ scale: 0.4, opacity: 0 }}
            custom={0.3}
            animate={h1Animation}
          >
            Esport
          </motion.h1>
        </section>
        <motion.p
          initial={{ opacity: 0, y: 100 }}
          animate={ElementsYAnimation}
          custom={2}
        >
          There are more than 3 billion players worldwide and only a handful of
          gamers are professional eSport athletes who can make a living by
          playing games
        </motion.p>
      </article>

      <div className="facts_plh">
        <motion.div
          className="animation_plh"
          initial={{ opacity: 0, x: 200 }}
          animate={factLeftAnimation}
          custom={1}
        >
          <div className="fact">
            <span>👏</span> Accessible to everyone
          </div>
        </motion.div>

        <motion.div
          className="animation_plh"
          initial={{ opacity: 0, x: 200 }}
          animate={factRightAnimation}
          custom={2}
        >
          <div className="fact">
            No need for sponsors <span>👏🏽</span>
          </div>
        </motion.div>

        <motion.div
          className="animation_plh"
          initial={{ opacity: 0, x: 200 }}
          animate={factRightAnimation}
          custom={4}
        >
          <div className="fact">
            Buy-ins as low as 5$ USD <span>👏🏻</span>
          </div>
        </motion.div>

        <motion.div
          className="animation_plh"
          initial={{ opacity: 0, x: 200 }}
          animate={factLeftAnimation}
          custom={3}
        >
          <div className="fact">
            Single player, no need to find or join teams <span>👏🏾</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DemocratizingSection;
