import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import topBarReducer from "../features/topBar/topBarSlice";
// import ElementsVisibilityReducer from "../features/ElementsVisibility/ElementsVisibilitySlice";
import headerReducer from "../features/Header/headerSlice";
import requirementsReducer from "../features/RequirementsModal/requirementsSlice";

import thunkMiddleware from "redux-thunk";

export const store = configureStore({
  reducer: {
    // ElementsVisibility: ElementsVisibilityReducer,
    Header: headerReducer,
    Requirements: requirementsReducer,
  },
  middleware: [thunkMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
