import "./style.scss";
import LogoIcon from "src/assets/icons/Global/new-main-logo-square.svg";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/app/hooks";
import { triggerJoinBtn } from "src/features/Header/headerSlice";
import { useIsMobile } from "src/hooks/useIsMobile";

const Header = ({ onModalToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile(769);

  const goToHome = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
  };

  const handleClick = () => {
    navigate("join");
  };

  const handleAnchor = () => {
    dispatch(triggerJoinBtn(""));
  };

  const handleLogo = () => {
    dispatch(triggerJoinBtn(""));
    goToHome();
  };
  return (
    <div
      className={
        location.pathname === "/"
          ? "header-container"
          : "header-container header_container_white_mode"
      }
    >
      <div className="header-plh"></div>
      <div className="content">
        <section className=" header-plh">
          <button onClick={handleLogo}>
            <div className="logo">
              <img src={LogoIcon} alt="" />
            </div>
            <p>TiltStar</p>
          </button>
        </section>

        {!isMobile && (
          <section className="anchor-plh">
            <NavLink to="/">Home</NavLink>
            <NavLink onClick={handleAnchor} to="/terms">
              Terms
            </NavLink>
            <NavLink onClick={handleAnchor} to="/contact">
              Contact
            </NavLink>
            {/* <NavLink onClick={handleAnchor} to="/contact">
              Referral
            </NavLink> */}
          </section>
        )}

        {!isMobile && (
          <section className="join-plh">
            <button
              onClick={handleClick}
              className={`glowing-btn active-tap-animation ${
                location.pathname === "/join" ? "selected_btn" : ""
              }`}
            >
              <span>Join Now</span>
            </button>
          </section>
        )}

        {isMobile && (
          <section className="mobile_header" onClick={() => onModalToggle()}>
            <button>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </section>
        )}
      </div>
    </div>
  );
};

export default Header;
