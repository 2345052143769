import { motion } from "framer-motion";
import "./style.scss";

const BackgroundLayer = ({ mapAnimation }) => {
  return (
    <>
      <motion.div
        className="background_layer"
        style={{ backgroundPositionY: mapAnimation }}
      ></motion.div>
      <div className="radial_gradient_layer"></div>
    </>
  );
};

export default BackgroundLayer;
