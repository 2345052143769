import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { useIsMobile } from "src/hooks/useIsMobile";
import { generateKey } from "src/utils/uniqueKey";
import "./style.scss";

import HelpIcon from "src/assets/icons/Global/help.svg";

const SideBar = ({ welcomePageSections, activeSection, handleClick }) => {
  const location = useLocation();
  const sideBarAnimation = useAnimationControls();
  const isMobile = useIsMobile(1280);

  useEffect(() => {
    if (location.pathname === "/") {
      sideBarAnimation.start((custom) => ({
        x: 0,
        opacity: 1,
        transition: { delay: custom * 0.3 },
      }));
    } else {
      sideBarAnimation.start((custom) => ({
        x: -100,
        opacity: 0,
        transition: { duration: 0 },
      }));
    }
  }, [location.pathname]);

  return (
    <motion.div
      initial={{ opacity: 0, x: -100 }}
      animate={sideBarAnimation}
      custom={1}
      className="side_bar"
    >
      <div className="dots-plh">
        {welcomePageSections.map((dot) => {
          if (!isMobile) {
            return (
              <div
                className={
                  dot.id === activeSection ? "dots dots_active" : "dots"
                }
                key={dot.id}
                onClick={() => handleClick(dot.name)}
              >
                <span className=""></span>
                <span className="active"></span>
              </div>
            );
          } else {
            if (dot.mobile) {
              return (
                <div
                  className={
                    dot.id === activeSection ? "dots dots_active" : "dots"
                  }
                  key={dot.id}
                  onClick={() => handleClick(dot.name)}
                >
                  <span className=""></span>
                  <span className="active"></span>
                </div>
              );
            }
          }
        })}
        {/* <div className="help">
          <img src={HelpIcon} alt="" />
        </div> */}
      </div>
    </motion.div>
  );
};

export default SideBar;
