import * as yup from "yup";

const nameMatch = /^[a-zA-Z]+$/;

export const contactValidation = yup.object().shape({
  // name: yup
  //   .string()
  //   .required("Please provide a name.")
  //   .min(2, "Name should contain at least 2 letters.")
  //   .matches(
  //     nameMatch,
  //     "Name should not include spaces and should consist of only English letters."
  //   ),
  email: yup
    .string()
    .required("Email is required.")
    .email("Please enter a valid email address."),
  message: yup
    .string()
    .required("A message is required.")
    .min(10, "The message should be at least 10 letters long.")
    .max(255, "The message must not exceed 255 characters."),
});

export const waitingListValidation = yup.object().shape({
  // name: yup
  //   .string()
  //   .required("Please provide a name.")
  //   .min(2, "Name should contain at least 2 letters.")
  //   .matches(
  //     nameMatch,
  //     "Name should not include spaces and should consist of only English letters."
  //   ),
  email: yup
    .string()
    .required("Email is required.")
    .email("Please enter a valid email address."),
});
