import "./style.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { generateKey } from "../../utils/uniqueKey";
import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const ModalNavigation = ({ isOpen, onModalToggle }) => {
  const navigationData = [
    {
      pageName: "home",
      path: "/",
      custom: 1,
    },
    {
      pageName: "Terms",
      path: "/terms",
      custom: 2,
    },
    {
      pageName: "Contact",
      path: "/contact",
      custom: 3,
    },
    {
      pageName: "Join",
      path: "/join",
      custom: 4,
    },
  ];

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const sideBarAnimation = useAnimationControls();
  const elementsAnimation = useAnimationControls();

  const handlePageClick = (pagePathName: any) => {
    navigate(pagePathName);
    onModalToggle();
  };

  useEffect(() => {
    if (isOpen) {
      sideBarAnimation.start({
        x: 0,
        opacity: 1,
        transition: { duration: 0.3 },
      });
      elementsAnimation.start((custom: number) => ({
        opacity: 1,
        transition: { delay: custom * 0.1 },
      }));
    } else {
      sideBarAnimation.start({
        x: -100,
        opacity: 0,
        transition: { duration: 0.3 },
      });
      elementsAnimation.start((custom: number) => ({
        opacity: 0,
        transition: { delay: custom * 0.2 },
      }));
    }
  }, [isOpen, sideBarAnimation, elementsAnimation]);

  return (
    <div
      className={
        isOpen
          ? "navigation_modal_container active"
          : "navigation_modal_container"
      }
    >
      <section className="content">
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={sideBarAnimation}
          className="modal_side_bar"
        >
          {navigationData.map((item) => {
            return (
              <motion.button
                initial={{ opacity: 0 }}
                animate={
                  location.pathname === item.path
                    ? { opacity: 1, transition: { duration: 0 } }
                    : elementsAnimation
                }
                custom={item.custom}
                key={generateKey()}
                className={location.pathname === item.path ? "active" : ""}
                onClick={() => handlePageClick(item.path)}
              >
                <span></span>
                <div className="current_section">
                  {/* <div className="icon">
                    <img src={item.icon} alt="" />
                  </div> */}
                  <p>{item.pageName}</p>
                </div>
              </motion.button>
            );
          })}
        </motion.div>
        <div className="current_section_container"></div>
      </section>
    </div>
  );
};

export default ModalNavigation;
