import "./style.scss";

// import SuccessIcon from "src/assets/icons/Global/check-mini.svg";
import SuccessIcon from "src/assets/icons/Global/check-green.svg";

const SuccessBlock = ({ message }) => {
  return (
    <div className="success">
      <p>{message}</p>
      <div className="icon">
        <img src={SuccessIcon} alt="" />
      </div>
    </div>
  );
};

export default SuccessBlock;
