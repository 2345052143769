import { createSlice } from "@reduxjs/toolkit";

interface IInitialState {
  joinBtn: string;
}

const initialState: IInitialState = {
  joinBtn: "",
};

export const headerSlice = createSlice({
  name: "affiliate",
  initialState,

  reducers: {
    triggerJoinBtn: (state, action) => {
      state.joinBtn = action.payload;
    },
  },
});

export const { triggerJoinBtn } = headerSlice.actions;

export default headerSlice.reducer;
